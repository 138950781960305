.videoWrapper {
    position: relative;
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    border-radius: 10px;
    background-color: var(--primary-color, rgba(100, 16, 173, 0.8));
    color: var(--secondary-color, #ffffff);
  }
  
  .videoElement {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    border-radius: 10px 10px 0 0; /* Rounded top, flat bottom */
    cursor: default;
  }
  
  /* Central play button */
  .centerPlayButton {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 120px;
    height: 120px;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: opacity 0.3s ease, visibility 0.3s ease;
    opacity: 1;
    visibility: visible;
  }
  
  .centerPlayButton:hover {
    box-shadow: 0 0 45px rgba(107, 97, 106, 0.8);
  }
  
  .playIcon,
  .pauseIcon {
    width: 60px;
    height: 60px;
    color: var(--primary-color, #ffffff);
  }
  
  .playIcon:hover {
    color: #a8a2ac; /* Changes the icon color to gray on hover */
  }
  
  /* Controls */
  .controls {
    position: absolute;
    bottom: 0; /* No space between controls and bottom of the video */
    left: 0;
    right: 0;
    display: flex; /* Ensure flex layout */
    align-items: center;
    padding: 10px;
    background: #24033a41;
    border-radius: 0 0 10px 10px; /* Rounded bottom */
    z-index: 10;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    flex-wrap: nowrap; /* Ensure controls are on one line */
  }
  
  .controls.show {
    opacity: 1;
  }
  
  .controls.hide {
    opacity: 0;
  }
  
  .playButton,
  .fullscreenButton,
  .muteButton {
    background: none;
    border: none;
    cursor: pointer;
    color: #ffffff; /* Set button color to white */
    margin-right: 10px; /* Add margin to increase space between icons */
    display: flex;
    align-items: center;
    flex-shrink: 0; /* Prevent buttons from shrinking */
  }
  
  .playButton svg,
  .fullscreenButton svg,
  .muteButton svg {
    width: 30px;
    height: 30px;
  }
  
  /* Adjust icon size and ensure they are fully visible */
  .volumeIcon,
  .fullscreenIcon,
  .playIcon,
  .pauseIcon {
    width: 90px;
    height: 90px;
  }
  
  /* Hover effect: change color to gray */
  .playButton:hover,
  .fullscreenButton:hover,
  .muteButton:hover {
    color: #a8a2ac;
  }
  
  /* .playButton:hover svg {
    box-shadow: 0 0 15px #a8a2ac;
  } */
  
  .playButton svg {
    transition: box-shadow 0.3s ease;
  }
  
  /* Progress Bar Styling */
  .progressBar {
    appearance: none;
    flex-grow: 1; /* Allow progress bar to grow and fill available space */
    height: 5px;
    margin: 0 10px;
    cursor: pointer;
    border-radius: 5px;
    background: #ccc; /* Light gray background */
    position: relative;
    max-width: 100%;
  }
  
  .progressBar::-webkit-slider-runnable-track {
    width: 100%;
    height: 6px;
    background: transparent;
  }
  
  .progressBar::-webkit-slider-thumb {
    appearance: none;
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background: #a100ff;
    position: relative;
    z-index: 2;
    cursor: pointer;
    border: 2px solid #a100ff;
    top: -3px;
    left: -1px;
  }
  
  .progressBar::-moz-range-thumb {
    appearance: none;
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background: #a100ff;
    position: relative;
    z-index: 2;
    cursor: pointer;
    border: 2px solid #a100ff;
  }
  
  .progressBar::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 5px;
    background: #a200ff96;
    border-radius: 5px;
    z-index: 1;
    width: var(--progress, 0%);
  }
  
  .progressBar:hover::before {
    background: #a200ff96;
  }
  
  .timeDisplay {
    color: #ffffff;
    font-size: 13px;
    margin-right: 10px;
    min-width: 30px;
  }
  
  .volumeContainer {
    display: flex;
    align-items: center;
    flex-shrink: 0; /* Prevent volume container from shrinking */
    margin-left: auto; /* Push volume control and subsequent elements to the right */
  }
  
  .volumeControl {
    appearance: none;
    width: 100px;
    height: 5px;
    margin-left: 10px;
    cursor: pointer;
    border-radius: 5px;
    background: #ccc; /* Light gray background */
    position: relative;
  }
  
  .volumeControl::-webkit-slider-runnable-track {
    background: transparent;
  }
  
  .volumeControl::-webkit-slider-thumb {
    appearance: none;
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background: #a100ff; /* White slider thumb */
    position: relative;
    z-index: 2;
    cursor: pointer;
    border: 2px solid #a100ff;
  }
  
  .volumeControl::-moz-range-thumb {
    appearance: none;
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background: #a100ff;
    position: relative;
    z-index: 2;
    cursor: pointer;
    border: 2px solid #a100ff;
  }
  
  .volumeControl::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 5px;
    background: #a200ff96; /* White for volume */
    border-radius: 5px;
    z-index: 1;
    width: var(--volume, 100%); /* Dynamic volume width */
  }
  
  /* Hover effect on controls */
  .controls button:hover,
  .controls input:hover {
    color: #a8a2ac;
  }
  
  .controls button:hover svg {
    color: #a8a2ac;
  }
  
  /* Adjustments to ensure icons are fully visible */
  .controls button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px; /* Default width */
    height: 40px; /* Default height */
    flex-shrink: 0; /* Prevent buttons from shrinking */
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .centerPlayButton {
      width: 60px;
      height: 60px;
    }
  
    .playIcon,
    .pauseIcon {
      width: 40px;
      height: 40px;
    }
  
    .playButton svg,
    .fullscreenButton svg,
    .muteButton svg {
      width: 25px;
      height: 25px;
    }

    .timeDisplay {
        font-size: 11px; /* Reduced font size */
        margin-right: 5px; /* Reduced margin */
        min-width: 40px; /* Reduced min-width */
      }
  
    .controls {
      padding: 6px;
    }
  
    .controls button {
      width: 30px;
      height: 30px;
      margin-right: 2px;
    }
  
    .progressBar {
      height: 3px; /* Reduced height */
      margin: 0 8px; /* Reduced margin */
      max-width: 55%;
    }
    .progressBar::before {
      height: 3px;
    }
  
    .progressBar::-webkit-slider-thumb,
    .progressBar::-moz-range-thumb {
      height: 10px;
      width: 10px;
    }
  
    .volumeControl {
      width: 60px; /* Reduced width */
      height: 3px; /* Reduced height */
      margin-left: 8px; /* Reduced margin */
    }
    .volumeControl::before {
      height: 3px;
    }
  
    .volumeControl::-webkit-slider-thumb,
    .volumeControl::-moz-range-thumb {
      height: 10px;
      width: 10px;
    }
  }
  
  @media (max-width: 480px) {
    .centerPlayButton {
      width: 50px;
      height: 50px;
    }
  
    .playIcon,
    .pauseIcon {
      width: 30px;
      height: 30px;
    }
  
    .playButton svg,
    .fullscreenButton svg,
    .muteButton svg {
      width: 20px;
      height: 20px;
    }
  
    .controls {
      padding: 6px;
      flex-wrap: nowrap; /* Ensure controls stay on one line */
    }
  
    .controls button {
      width: 30px;
      height: 30px;
      margin-right: 5px; /* Reduced margin */
    }
  
    .progressBar {
      height: 2px; /* Further reduced height */
      margin: 0 5px; /* Further reduced margin */
      flex-grow: 1;
      max-width: 30%; /* Limit the width to 25% of the container */
    }
    .progressBar::before {
      height: 2px;
    }
  
    .progressBar::-webkit-slider-thumb,
    .progressBar::-moz-range-thumb {
      height: 8px;
      width: 8px;
    }
  
    .volumeControl {
      width: 30px; /* Further reduced width */
      height: 2px; /* Further reduced height */
      margin-left: 5px; /* Further reduced margin */
    }
    .volumeControl::before {
      height: 2px;
    }
  
    .volumeControl::-webkit-slider-thumb,
    .volumeControl::-moz-range-thumb {
      height: 8px;
      width: 8px;
    }
  
    .timeDisplay {
      font-size: 9px; /* Reduced font size */
      margin-right: 0px; /* Reduced margin */
      min-width: 50px; /* Reduced min-width */
    }
  }
  