@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root,
.app {
    height: 100%;
    width: 100%;
    font-family: "Poppins", sans-serif;
    background-color: #210837;
}

.input-box-shadow {
    filter: drop-shadow(-4px -4px 44px rgba(0, 0, 0, 0.08));
}

.slick-prev:before,
.slick-next:before {
    color: black;
    font-size: 40px;
}

.slick-next:before {
    margin-left: -25px;
}

.slick-prev {
    z-index: 9999;
}

.category:hover,
.icon {
    fill: #1a9068;
}